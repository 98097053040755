import React, { useState, useEffect } from 'react';
import { Button, Form } from "react-bootstrap";
import axios from 'axios';
import '../css/home.css';
import { Link } from "react-router-dom";
import Cookie from 'js-cookie';
import Cookies from 'js-cookie';



const TherapistProfile = () => {
    const [sessionName, setSessionName] = useState('');
    const [personaName, setPersonaName] = useState('');
    const [selectedPhotourl, setSelectedPhotourl] = useState(null);

    const handleSessionNameChange = (event) => {
        setSessionName(event.target.value);
        Cookie.set('sessionname', event.target.value);
    };

    const handlePersonaNameChange = (event) => {
        setPersonaName(event.target.value);
        Cookie.set('personaname', event.target.value);
    };

    const handlePhotourlSelection = (photourl) => {
        setSelectedPhotourl(photourl);
        Cookie.set('photourl', photourl);

    };

    const photourls = [
        'Amitabh_Bachchan.jpg',
        'Bill_Belichick.jpg',
        'Frasier_Crane.png',
        'logo.png',
    ];
    useEffect(() => {
        const sessioninfoCookie = Cookies.get('sessioninfo');
        let sessioninfo = {};
        if (sessioninfoCookie) {
            sessioninfo = JSON.parse(sessioninfoCookie);
            setSessionName(decodeURIComponent(sessioninfo.session_name));
            setPersonaName(decodeURIComponent(sessioninfo.persona_name));
            setSelectedPhotourl(decodeURIComponent(sessioninfo.photo_url))
        }
    }, []);;
    return (
        <div id='homediv' className="container-fluid px-0">
            <div className="container-fluid px-4 shadow-lg rounded">
                <div className="row mt-2 mb-0 rounded">
                    <div className="col-md-12 psyd-primary-bg-color p-3 psyd-font-accent rounded-top text-center">
                        <h1>Tailor Therapist Profile</h1>
                    </div>
                </div>
                <div className="row px-0 mt-0 mb-1 psyd-secondary-bg-color psyd-font shadow-lg align-items-center rounded">
                    <div className="col-md-12 p-3 d-flex justify-content-center">
                        To enhance your therapy experience and help you stay organized, we kindly ask you to assign a name to this session. You can categorize your sessions based on your mental health concern or any grouping of your choice, making it easier to track your progress over time. Additionally, please provide the name of your therapist and select a profile picture for your therapist. This will make your sessions feel more personal and relatable, allowing you to connect with your therapist in a more meaningful way.
                    </div>
                </div>
            </div>
            <div className="container mt-3" style={{ minHeight: '50vh' }}>
                <div className="row mb-3 g-2">
                    <div className="col-md-3 p-3 rounded psyd-secondary-bg-color">
                        <label>Session Name:</label>
                    </div>
                    <div className="col-md-9">
                        <input type="text" className="form-control" value={sessionName} onChange={handleSessionNameChange} style={{ height: '55px' }} />

                    </div>
                </div>
                <div className="row mb-3 g-2">
                    <div className="col-md-3 p-3 rounded psyd-secondary-bg-color">
                        <label>Therapist Name:</label>
                    </div>
                    <div className="col-md-9">
                        <input type="text" className="form-control" value={personaName} onChange={handlePersonaNameChange} style={{ height: '55px' }} />
                    </div>
                </div>
                <div className="row mb-3 g-2">
                    <div className="col-md-3 p-3 rounded psyd-secondary-bg-color">
                        <label>Therapist Avatar:</label>
                    </div>
                    <div className="col-md-6">
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {photourls.map((photourl, index) => (
                                <img
                                    key={index}
                                    src={`./img/${photourl}`}
                                    alt={`Photourl ${index + 1}`}
                                    onClick={() => handlePhotourlSelection(photourl)}
                                    style={{
                                        width: '50px', // Adjust width as needed
                                        height: '50px',
                                        borderRadius: '50%', // Make image circular
                                        margin: '5px', // Add space between images
                                        border: selectedPhotourl === photourl ? '2px solid blue' : 'none',
                                        cursor: 'pointer',
                                    }}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <Link to={'/consent'} style={{ display: 'flex', justifyContent: 'center' }}>
                <Button>Next</Button>
            </Link>

        </div>
    );
}

export default TherapistProfile;
