// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .app.campfire{
    background-color: #fbe7d7;
}

.app.luxury{
    background-color: #88ff96;
}

.app.starry{
    background-color: #8ac8f4;
} */

.button-block{
    display: flex;
    justify-content: space-between;
    padding-left: 150px;
    padding-right: 150px;
}

#btn-camp{
    background-color: #e8b14a;
    color: #8f5856;
}

#btn-lux{
    background-color: #264a44;
    color: #a28644;
}

#btn-star{
    background-color: #6ca5c1;
    color: #f0c778;
}

#homediv{
    height: 100%;
}

.fixed-image-size {
    width: 100%; /* Ensure full width of the card */
    height: 200px; /* Set a fixed height for images */
    object-fit: cover; /* Maintain aspect ratio and cover the space */
}


.card-body {
    height: 100%; /* Ensure the card body takes full height */
}
`, "",{"version":3,"sources":["webpack://./src/css/home.css"],"names":[],"mappings":"AAAA;;;;;;;;;;GAUG;;AAEH;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW,EAAE,kCAAkC;IAC/C,aAAa,EAAE,kCAAkC;IACjD,iBAAiB,EAAE,8CAA8C;AACrE;;;AAGA;IACI,YAAY,EAAE,2CAA2C;AAC7D","sourcesContent":["/* .app.campfire{\n    background-color: #fbe7d7;\n}\n\n.app.luxury{\n    background-color: #88ff96;\n}\n\n.app.starry{\n    background-color: #8ac8f4;\n} */\n\n.button-block{\n    display: flex;\n    justify-content: space-between;\n    padding-left: 150px;\n    padding-right: 150px;\n}\n\n#btn-camp{\n    background-color: #e8b14a;\n    color: #8f5856;\n}\n\n#btn-lux{\n    background-color: #264a44;\n    color: #a28644;\n}\n\n#btn-star{\n    background-color: #6ca5c1;\n    color: #f0c778;\n}\n\n#homediv{\n    height: 100%;\n}\n\n.fixed-image-size {\n    width: 100%; /* Ensure full width of the card */\n    height: 200px; /* Set a fixed height for images */\n    object-fit: cover; /* Maintain aspect ratio and cover the space */\n}\n\n\n.card-body {\n    height: 100%; /* Ensure the card body takes full height */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
