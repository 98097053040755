import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Form, Container } from 'react-bootstrap';
import Cookie from 'js-cookie';
import Cookies from 'js-cookie';

const Share = () => {
    const [errorMessage, setErrorMessage] = useState('');
    const [formData, setFormData] = useState({
        user_id:'',
        shared_with_email: '',
        shared_with_user_id: '',
        sharing_authorization_code: ''
    });

    // Handle form field change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    useEffect(() => {
        const userCookie = Cookies.get('userinfo');
        let userinfo = {};
        if (userCookie) {
            userinfo = JSON.parse(userCookie);
            setFormData(userinfo);  // Set form data from the fetched user
        }
        setErrorMessage('if you would like to share your sessions with another user, please prvide their PsyD email id and authorization code.');
    }, []);

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        const userCookie = Cookies.get('userinfo');
        let userinfo = {};
        let userId = ''
        if (userCookie) {
            userinfo = JSON.parse(userCookie);
            userId = userinfo.user_id;
        }
        try {
            if (formData.shared_with_email && formData.sharing_authorization_code && formData.sharing_authorization_code !== 'None') {
                axios.put(`/api/share`, formData)
                    .then((response) => {
                        if (response.data.exists) {
                            formData.shared_with_user_id = response.data.sharedwithuserid;
                            setErrorMessage('Shared successfully.');
                        } else {
                            setErrorMessage('The email and Sharing Authorization Code did not match');
                        }
                } )
                .catch((error) => {
                    console.error('Error updating data:', error);
                });    
            } else {
                setErrorMessage('Please provide valid values for Shared with User email and Sharing Authorization Code');
            }
        } catch (error) {
            setErrorMessage(error);
        }
    };

    return (
        <div id='homediv' className="container-fluid px-0">
             <div className="container-fluid px-4 shadow-lg rounded">
                <div className="row mt-2 mb-0 rounded">
                    <div className="col-md-12 psyd-primary-bg-color p-3 psyd-font-accent rounded-top text-center">
                        <h1>Share with human therapist</h1>
                    </div>
                </div>
                <div className="row px-0 mt-0 mb-1 psyd-secondary-bg-color psyd-font shadow-lg align-items-center rounded">
                    <div className="col-md-12 p-3 d-flex justify-content-center">
                    We understand that sometimes sharing your therapy sessions with a trusted individual, such as your human therapist, parent, or guardian, can be beneficial to your mental health journey. On our AI-powered online therapy platform, you can easily share your sessions with a registered user of the platform. In order to do so, you will need to obtain a **sharing authorization code** from the person you wish to share your sessions with. This ensures your privacy and consent are respected while allowing for the necessary support from trusted individuals. Please make sure both you and the person you're sharing with are registered users of the platform to facilitate this process.                    </div>
                </div>
            </div>
            <div className="container mt-3 psyd-primary-bg-color" style={{ minHeight: '50vh' }}>
            <Form>
                <div className="row mb-3 g-2">
                    <div className="col-md-3 p-3 rounded psyd-secondary-bg-color">
                        <label>Shared with User email</label>
                    </div>
                    <div className="col-md-3">
                        <input className="form-control" type="text" name="shared_with_email" value={formData.shared_with_email} onChange={handleChange} style={{ height: '55px' }} />
                    </div>
                    <div className="col-md-3 p-3 rounded psyd-secondary-bg-color">
                        <label>Sharing Authorization Code</label>
                    </div>
                    <div className="col-md-3">
                        <input className="form-control" type="text" name="sharing_authorization_code" value={formData.sharing_authorization_code} onChange={handleChange} style={{ height: '55px' }} />
                    </div>
                </div>
                <div className="row mb-3 g-2">
                    <div className="col-md-12 rounded psyd-primary-bg-color">
                        <div className='psyd-font-light'>{errorMessage}</div>
                    </div>
                </div>
                <div className="row mb-3 g-2">
                    <div className="col-md-12 rounded psyd-primary-bg-color d-flex justify-content-center align-items-center">
                        <Button variant="primary" onClick={handleSubmit}>
                            Share
                        </Button>
                    </div>
                </div>
            </Form>
            </div>
        </div>
    );
};

export default Share;
