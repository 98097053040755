// src/therapy.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookie from 'js-cookie';
import Cookies from 'js-cookie';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import { Link } from "react-router-dom"
import { useNavigate } from 'react-router-dom';
// Set axios default settings to include cookies
axios.defaults.withCredentials = true;

function Sessions() {
    const [messages, setMessages] = useState([]);
    const navigate = useNavigate();
    const fetchMessages = async () => {
        try {
            const response = await axios.get('/api/usersessions');
            //const sessionid = response.data.sessionid;
            //Cookie.set('sessionid', sessionid.sessionid);  // Store sessionid in cookies
            const tempmessages = response.data.therapyhistory;
            const parsedMessages = tempmessages?.map(tempmessage => JSON.parse(tempmessage)) || [];
            if (parsedMessages.length === 0) {                 
                navigate('/messages'); // Fixed the quotation mark
            }
            else { setMessages(parsedMessages); }
        } catch (error) {
            console.error('Error fetching messages', error);
        }
    };

    const handleSendInput = async (session_id) => {
        try {
            if (session_id) {
                //continue with existing session
                Cookies.remove('sessionid');
                Cookie.set('sessionid', session_id);
                navigate('/consent'); // Fixed the quotation mark
            }
            else {
                //start a new session
                Cookies.remove('sessionid');
                Cookies.remove('sessioninfo');
                Cookies.remove('agegroupid');
                Cookies.remove('relationshipstatusid');
                Cookies.remove('professionalstatusid');
                Cookies.remove('jobcategoryid');
                Cookies.remove('psychologicalconcernid');
                Cookies.remove('therapistpersonaid');
                Cookies.remove('sessionname');
                Cookies.remove('personaname');
                Cookies.remove('photourl');
                navigate('/agegroup');
            }
        } catch (error) {
            console.error('Error sending input', error);
        }
    };

    const handleCustomizeInput = async (session_id) => {
        try {
            if (session_id) {
                //customize an existing session
                Cookies.remove('sessionid');
                Cookie.set('sessionid', session_id);
                const response = await axios.get('/api/sessioninfo');
                const sessioninfo = response.data.sessioninfo;
                Cookie.set('sessioninfo', sessioninfo);
                navigate('/agegroup');
            }
        } catch (error) {
            console.error('Error sending input', error);
        }
    };


    useEffect(() => {
        const userCookieExists = Cookies.get('userid') !== undefined;
        if (userCookieExists) {
            fetchMessages();
        } else {
            const sessionCookieExists = Cookies.get('sessionid') !== undefined;
            if (sessionCookieExists) {
                fetchMessages();
            } else {
                navigate('/login'); // Fixed the quotation mark
            }
        }
    }, []);
    const convertUtcToLocal = (utcDateString) => {
        const utcDate = new Date(utcDateString);
        return utcDate.toLocaleString(); // or use toLocaleTimeString() for just the time
    };

    return (

        <div id='homediv' className="container-fluid px-0">
            <div className="container-fluid px-4 shadow-lg rounded">
                <div className="row mt-2 mb-0 rounded">
                    <div className="col-md-12 psyd-primary-bg-color p-3 psyd-font-accent rounded-top text-center">
                        <h1>My Sessions</h1>
                    </div>
                </div>
                <div className="row px-0 mt-0 mb-1 psyd-secondary-bg-color psyd-font shadow-lg align-items-center rounded">
                    <div className="col-md-6 p-0 d-flex justify-content-center">
                        <div className="card">
                            <img
                                className="card-img-top img-fluid "
                                src="../img/messages.jpg" // Replace with your image path
                                alt="Guest Session"
                            />
                        </div>
                    </div>
                    <div className="col-md-6 p-3">
                        On this page, you'll find a list of your three most recent sessions, providing you with an easy reference to your past discussions. You can choose to continue one of these sessions to delve deeper into the topics you’ve explored, or you can opt to start a new session if you wish to address different concerns. This streamlined approach ensures that you can easily navigate your therapeutic journey and receive the support you need, tailored specifically to your evolving needs.
                    </div>
                </div>
            </div>
            <div className="container-fluid px-4 shadow-lg rounded">
                <div className="row mt-2 p-0 align-items-center">
                    <div className="col p-0">
                        <div className="row p-0">
                            {messages.length === 0 ? (
                                <div className="col rounded psyd-secondary-bg-color psyd-font text-center">No sessions yet</div>
                            ) : (
                                messages.map((record) => (
                                    <div className="col-md-4 mb-2 rounded" key={record.history_id}>
                                        <div className="psyd-chat p-0">
                                            <div className="mb-0 mt-0 p-0 psyd-primary-bg-color psyd-font-light border border-left border-top border-right border-3 rounded-top">
                                                <div className="psyd-primary-bg-color psyd-font-light p-1">Session Date:</div>
                                                <div className="p-1 psyd-secondary-bg-color psyd-font">
                                                    {convertUtcToLocal(record.created_timestamp)}
                                                </div>
                                            </div>
                                            <div className="mb-0 mt-0 p-0 psyd-primary-bg-color psyd-font-light border border-left border-right border-3">
                                                <div className="psyd-primary-bg-color psyd-font-light p-1">Request:</div>
                                                <div className="p-1 psyd-secondary-bg-color psyd-font">
                                                    {record.input_text.substring(0, 300)}
                                                </div>
                                            </div>
                                            <div className="mb-1 mt-0 p-0 psyd-primary-bg-color psyd-font-light border border-left border-bottom border-right border-3 rounded-bottom">
                                                <div className="psyd-primary-bg-color psyd-font-light p-1">Response:</div>
                                                <div className="p-1 psyd-secondary-bg-color psyd-font">
                                                    {record.response_text.substring(0, 300)}
                                                </div>
                                            </div>
                                            <div className="mb-0 mt-0 p-0 psyd-primary-bg-color psyd-font-light d-flex justify-content-center">
                                                <Link to="" style={{ marginRight: '10px' }}>  {/* Add some space between buttons */}
                                                    <Button onClick={() => handleCustomizeInput(record.session_id)}>Personalize this Session</Button>
                                                </Link>
                                                <Link to="/consent">
                                                    <Button onClick={() => handleSendInput(record.session_id)}>Continue this Session</Button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>
                <Form className="mt-3">
                    <Form.Group className="text-center">
                        <Button variant="primary" onClick={() => handleSendInput()} className="ms-2">Start a new session</Button>
                    </Form.Group>
                </Form>
            </div>

        </div>

    );
}

export default Sessions;
