import React from 'react';
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import Cookies from 'js-cookie';

const Profile = ({ changeMenuOption }) => {
  const handleLogout = () => {
    const useridcookieExists = Cookies.get('userid') !== undefined;
    if (useridcookieExists) {
      Cookies.remove('userid'); // Use string literal for cookie name
    }

    const userinfocookieExists = Cookies.get('userinfo') !== undefined;
    if (userinfocookieExists) {
      Cookies.remove('userinfo'); // Use string literal for cookie name
    }
    const usersubscriptioninfoExists = Cookies.get('usersubscriptioninfo') !== undefined;
    if (usersubscriptioninfoExists) {
      Cookies.remove('usersubscriptioninfo'); // Use string literal for cookie name
    }
    changeMenuOption('login', 'Login', '/login');
  };

  return (

    <div id='homediv' className="container-fluid px-0">
      <div className="container-fluid px-4 shadow-lg rounded">
        <div className="row mt-2 mb-0 rounded">
          <div className="col-md-12 psyd-primary-bg-color p-3 psyd-font-accent rounded-top text-center">
            <h1>Settings</h1>
          </div>
        </div>
        <div className="row px-0 mt-0 mb-1 psyd-secondary-bg-color psyd-font shadow-lg align-items-center rounded">
          <div className="col-md-12 p-3 d-flex justify-content-center">
            We encourage you to take a moment to update your profile settings. Keeping your profile information, including details such as your preferred name, pronouns, and user preferences, up to date helps us provide a more personalized and supportive therapy experience. Additionally, updating your Human Integration information, such as details about your human therapist or other trusted support persons, can enhance the quality of your sessions by allowing for better coordination and continuity of care. By ensuring that all your information is current, you'll help us create a more tailored and effective mental health experience that meets your needs.
          </div>
        </div>
        <div className="row p-3 mt-0 mb-1 psyd-secondary-bg-color psyd-font shadow-lg rounded justify-content-center">
          <div className="col-md-12 text-left">
            <Link to='/user' className='custom-link'>
            Update Profile Information
            </Link>
          </div>
        </div>
        <div className="row p-3 mt-0 mb-1 psyd-secondary-bg-color psyd-font shadow-lg rounded justify-content-center">
          <div className="col-md-12 text-left">
            <Link to='/share' className='custom-link'>
              Update Human Integration information
            </Link>
          </div>
        </div>
        <div className="row p-3 mt-0 mb-1 psyd-secondary-bg-color psyd-font shadow-lg rounded justify-content-center">
          <div className="col-md-12 text-start">
            <Link to='/theme-select' className='custom-link'>
              Customize User Interface
            </Link>
          </div>
        </div>
        <div className="row p-3 mt-0 mb-1 psyd-secondary-bg-color psyd-font shadow-lg rounded justify-content-center">
          <div className="col-md-12 text-center">
            <Link to='/'>
              <Button onClick={handleLogout}>Logout</Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Profile;
